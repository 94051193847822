import React from "react"
import { injectIntl, Link, intlShape } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import { navigate, useStaticQuery, graphql } from "gatsby"

import "../style/global.scss"
import Layout from "../components/Layout"
import TileEcard from "../components/Tiles/TileEcard"
import TileMatching from "../components/Tiles/TileMatching"
import TileTask from "../components/Tiles/TileTask"
import TileImagine from "../components/Tiles/TileImagine"

const IndexPage = ({ location, intl }) => (
  <Layout location={location}>
    <div className="main-w3layouts wrapper">
      <h1
        style={{
          color: "white",
          textAlign: "center",
          marginTop: 30,
        }}
      >
        Ulli bezahlt die Entwickler nicht!
      </h1>

      <h1
        style={{
          color: "white",
          textAlign: "center",
          marginTop: 30,
        }}
      >
        Schäm dich!
      </h1>
      
    </div>
  </Layout>
)

IndexPage.propTypes = {
  location: PropTypes.object,
  intl: intlShape,
}

export default injectIntl(IndexPage)

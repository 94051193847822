import React from "react"
import { injectIntl, Link, intlShape } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import { navigate, useStaticQuery, graphql } from "gatsby"

import tileStyle from "./tile.module.scss"

const TileEcard = ({ intl }) => {
  const product = {
    title: "e-card-mobile-title-3",
    text: "e-card-mobile-section-3",
    icon: "imagine.png",
  }

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "imagine.png" }) {
        childImageSharp {
          fluid(maxWidth: 256) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Paper
      style={{
        marginBottom: 10,
        padding: 10,
        paddingBottom: 15,
        display: "flex",
        flexDirection: "row",
      }}
      className={tileStyle.tile}
      key={product.text}
      className="flex-row d-flex align-items-center"
    >
      <div
        className="col-3 d-flex center-block align-items-center"
        style={{
          width: "70%",
          marginLeft: 10,
          marginRight: "2%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Img
          style={{ width: "100%" }}
          fluid={data.placeholderImage.childImageSharp.fluid}
          alt={product.title}
        />
      </div>
      <div className="col-9 d-flex align-items-center">
        <div className="d-flex flex-column bd-highlight">
          <h4 style={{ marginBottom: 8, marginTop: 0 }}>
            {intl.formatMessage({ id: product.title })}
          </h4>
          <p className="text-left p-text" style={{ textAlign: "left" }}>
            {intl.formatMessage({ id: product.text })}
          </p>
        </div>
      </div>
    </Paper>
  )
}

TileEcard.propTypes = {
  product: PropTypes.any,
}

export default injectIntl(TileEcard)
